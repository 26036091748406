const applyConfig = (config) => {
  config.settings.pluggableStyles = [
    ...(config.settings.pluggableStyles || []),
    {
      id: 'seashellBox',
      title: 'Seashell',
      cssClass: 'seashell-box',
    },
    {
      id: 'tertiaryBox',
      title: 'Electric',
      cssClass: 'tertiary-box',
    },
    {
      id: 'primaryBox',
      title: 'Maya',
      cssClass: 'primary-box',
    },
    {
      id: 'grayBox',
      title: 'Albaster',
      cssClass: 'gray-box',
    },
    {
      id: 'blackBox',
      title: 'Black',
      cssClass: 'black-box',
    },

    {
      id: 'secondaryBox',
      title: 'Robin Egg',
      cssClass: 'secondary-box',
    },

  ];
  config.settings.available_colors = [
    '#65bbec',
    '#41ccd7',
    '#93e5e8',
    '#efeae0',
    '#fcf3ec',
    '#000000',
    '#eeeeee',
    '#ffffff',
  ];
  config.settings.image_crop_aspect_ratios = [
    {
      label: '16:9',
      ratio: 16 / 9,
    },
    {
      label: '1:1',
      ratio: 1,
    },
    {
      label: '2:3',
      ratio: 2 / 3,
    },
  ];
  return config;
};

export default applyConfig;
