/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import social01 from './ico-social01.svg';
import social03 from './ico-social03.svg';
import social04 from './ico-social04.svg';
import social07 from './ico-social07.svg';
import social08 from './ico-social08.svg';
import './Footer.css';


const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({intl}) => {
  const {settings} = config;
  const lang = useSelector((state) => state.intl.locale);
  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      color="black"
      textAlign="center"
      id="footer"
    >
      <Container>
        <div basic inverted color="black" className="discreet">
          <Grid className="ui four column grid">
            <Grid.Column width={12}  textAlign='center'>
              <p id="social">
                <a href="https://www.facebook.com/epicsunpuntacana" target="_blank">
                  <img src={social01} alt="facebook" width="30" height="30"/></a>&nbsp;
                <a href="https://www.instagram.com/epicsunpuntacana/" rel="noopener" target="_blank">
                  <img src={social08} alt="instagram" width="30" height="30"/></a>&nbsp;
                <a href="https://maps.app.goo.gl/rcnRBLqgNEZCc38w5">
                  <img src={social07} alt="Directions" width="30" height="30"/></a>&nbsp;
              </p>
            </Grid.Column>
          </Grid>
        </div>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property
  {
    Object
  }
  propTypes Property types.
 * @static
 */
Footer.propTypes =
  {
    /**
     * i18n object
     */
  }
;

export default injectIntl(Footer);
